import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Checkbox,
  TextField,
  TextareaAutosize,
  Autocomplete
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';

import styles from './styles.module.scss';
import './styles.global.scss';
import {downloadFile, downloadOriginalFile, removeDocumentFile, removeDocumentOriginalFile} from '../../sagas';
import { withDimensions } from '@common/HOC';
import DocumentDropzone from '@common/components/DocumentDropzone';
import { displayErrorMessage } from '@utils/displayErrorMessage';
import { deepClone } from '@utils/deepClone';
import ConfirmDeletionModal from '@common/components/ConfirmDeletionModal';
import { getApplicants } from '@applicants/sagas';
import ProgressModal from '@common/components/ProgressModal';
import { getFileTypeObject, isFilePreviewSupported } from '@utils/helpers';
import {isJourneyEditAllowed, isPartner, USER_ROLE} from '@utils/authorisation';
import ReactDOM from 'react-dom';
import ValidationErrorLabel from '@common/components/ValidationErrorLabel';
import config from '@src/config';
import { isFunc } from '@utils/functions';


const DISABLED_COLOR = '#D3D4D8';
const DOCUMENT_STATUSES = ['Pending', 'Uploaded', 'Reviewed', 'Rejected'];

const spinner = <CircularProgress size={80} className={styles.globalProgress} />;
const rootEl = document.createElement('div');
rootEl.id = 'spinnerWrapperAFMID';

const AttachFilesModal = ({
                            open,
                            setOpen,
                            document,
                            hideJourneySelector = false,
                            onUpload,
                            isUploading,
                            currentProgress,
                            purpose = 'attach',
                            updateDocumentsList,
                            journeys,
                            documentTypes,
                            definedJourney,
                            userRole,
                            currentUser,
                            limitationMode = '',
                            applicants,
                            mustBeInternal = false,
                            hideCheckBoxes = false,
                            preventFileRemoving = false,
                            requiredUserNotRequired = false,
}) => {
  const dimensions = useSelector(state => state.dimensions);
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [mode, setMode] = useState('new'); //new|files|original
  const [availableModes, setAvailableModes] = useState([]);
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [category, setCategory] = useState(null);
  const [type, setType] = useState({});
  const [types, setTypes] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState({});
  const [possibleCategories, setPossibleCategories] = useState([]);
  const [body, setBody] = useState({});
  const [journeyUsers, setJourneyUsers] = useState([]);
  const [journeySearchString, setJourneySearchString] = useState('');
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationParameters, setConfirmationParameters] = useState({});
  const [downloadUrl, setDownloadUrl] = useState(undefined);
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(true);
  const [isTypeDisabled, setIsTypeDisabled] = useState(true);
  const [touchedInputs, setTouchedInputs] = useState(-1);

  const [isEditingSupported, setIsEditingSupported] = useState(false);

  const [isSignatureRequired, setIsSignatureRequired] = useState(false);

  const isMobileSafari = window.mobileCheck() && window.safariCheck();

  useEffect(() => {
    if (!open) {
      setFiles([]);
      setOriginalFiles([]);
      setSelectedJourney({});
      setBody({});
      setAttachedFiles([]);
      setTouchedInputs(-1);
    }
  }, [open]); //eslint-disable-line

  useEffect(() => {
    if (!!definedJourney && !!user?.id && isFunc(onUpload)) {
      setIsEditingSupported(isJourneyEditAllowed(user, definedJourney));
    } else {
      setIsEditingSupported(isFunc(onUpload));
    }
  }, [onUpload, definedJourney, user]); //eslint-disable-line

  useEffect(() => {
    if (files?.length && (userRole === USER_ROLE.Customer || isPartner(user)) && purpose === 'create') {
      setBody({...body, currentStatus: 'Uploaded'});
    }
  }, [files, user]); //eslint-disable-line

  useEffect(() => {
    setOpenProgressModal(!!isUploading && !!files.length);
  }, [isUploading]); //eslint-disable-line

  useEffect(() => {
    setIsCategoryDisabled((purpose !== 'create' && (userRole === USER_ROLE.Customer || isPartner(user))) || (!!limitationMode && limitationMode !== 'settlement_finalization' && limitationMode !== 'actionDocument'));
    setIsTypeDisabled((!!document?.files?.length && (userRole === USER_ROLE.Customer || isPartner(user)))
      || (!document?.includeSubTypes && (userRole === USER_ROLE.Customer || isPartner(user)) && purpose !== 'create')
      || ['loanConditionalApproval', 'loanFormalApproval', 'property_contract', 'related_documents', 'exchangedContract', 'loanValuationReport', 'certificateOfCurrency', 'reviewed_contract_of_sale'].includes(limitationMode));
  }, [purpose, limitationMode, userRole, document, user]); //eslint-disable-line

  useEffect(() => {
    if (userRole !== USER_ROLE.Customer && !isPartner(user) && (journeys?.length || definedJourney || !!limitationMode) && !!open) {
      const defaultJourney = document?.journey ? journeys?.find((j) => j.id === document.journey) : journeys?.[0];
      if (!definedJourney) setSelectedJourney(defaultJourney);
      else setSelectedJourney(definedJourney);
      let newBody = {
        journey: defaultJourney?.id ?? defaultJourney?._id ?? definedJourney ?? undefined,
        currentStatus: document?.currentStatus ?? 'Pending',
        customDescription: document?.customDescription ? document.customDescription : undefined,
        includeSubTypes: document?.includeSubTypes ?? false,
        filesToBeSigned: document?.filesToBeSigned ?? [],
        requiredUser: document?.requiredUser ?? defaultJourney?.user?.id ?? defaultJourney?.user?._id ?? defaultJourney?.user ?? currentUser?.id ?? currentUser?._id,
        user: document?.user ?? user?.id ?? user?._id ?? (limitationMode === 'identity' || limitationMode === 'certificateOfCurrency'
            ? document?.requiredUser ?? defaultJourney?.user?.id ?? defaultJourney?.user?._id ?? defaultJourney?.user ?? currentUser?.id ?? currentUser?._id
            : undefined
        ),
        reviewedBy: document?.reviewedBy ?? undefined
      };

      if (!!limitationMode && newBody && newBody.currentStatus !== 'Pending' && (purpose !== 'create' || (userRole !== USER_ROLE.Customer && !isPartner(user))) && limitationMode !== 'certificateOfCurrency' && !mustBeInternal) {
        newBody.currentStatus = 'Pending';
      } else if (!!mustBeInternal && newBody && newBody.currentStatus !== 'Uploaded') {
        newBody.currentStatus = 'Uploaded';
      }

      setBody(newBody);
      setIsSignatureRequired(!!document?.filesToBeSigned?.length);
    } else {
      if (definedJourney && !selectedJourney?.id) setSelectedJourney(definedJourney);
      setBody({
        currentStatus: document?.currentStatus ?? 'Pending',
      });
      setIsSignatureRequired(false);
    }
  }, [userRole, journeys, document, definedJourney, open, limitationMode, currentUser, user, mustBeInternal]); //eslint-disable-line

  useEffect(() => {
    if (purpose !== 'view') setMode('new');
    else setMode('files');
    if (purpose === 'create') {
      setType({});
    }
  }, [purpose]); //eslint-disable-line

  useEffect(() => {
    const setFormattedUsers = (apps) => {
      setJourneyUsers(apps.map((applicant) => {
        return {
          id: applicant.user?.id,
          name: `${applicant.name} ${applicant.middlename ? `${applicant.middlename} ` : ''}${applicant.surname}`,
          isPrimary: applicant.isPrimary
        };
      }));
    };

    if (documentTypes) setPossibleCategories(documentTypes.map((dt) => dt.category).filter(distinct));

    if (selectedJourney?.id) {
      if (applicants?.length) {
        setFormattedUsers(applicants);
      } else {
        if (requiredUserNotRequired) {
          setFormattedUsers([]);
        } else {
          dispatch(getApplicants({
            journeyId: selectedJourney.id,
            onSuccess: (data) => {
              const retrievedApplicants = data?.elements ?? [];
              setFormattedUsers(retrievedApplicants);
            },
            onError: (data) => {
              displayErrorMessage(data, 'Unable to retrieve applicants');
            }
          }));
        }
      }
    }

    if (document) {
      let newSelectedJourney;
      if (journeys) newSelectedJourney = journeys.find((j) => j?.id === document.journey);
      else if (definedJourney) newSelectedJourney = definedJourney;
      else newSelectedJourney = undefined;

      setSelectedJourney(newSelectedJourney);

      if (userRole !== USER_ROLE.Customer && !isPartner(user)) {
        setBody({
          ...body,
          includeSubTypes: !!document.includeSubTypes,
          currentStatus: document.currentStatus ?? (document.files?.length && purpose === 'create' ? 'Uploaded' : 'Pending'),
          customDescription: document.customDescription ?? undefined,
          requiredUser: (limitationMode === 'certificateOfCurrency'
              ? (newSelectedJourney?.user?.id ?? newSelectedJourney?.user?._id ?? newSelectedJourney?.user)
              : undefined) ?? document?.requiredUser ?? currentUser?.id ?? currentUser?._id ?? document?.user ?? selectedJourney?.user?.id ?? selectedJourney?.user,
          user: document?.user ?? user?.id ?? user?._id ?? undefined,
          reviewedBy: document?.reviewedBy ?? document.user ?? undefined,
          journey: selectedJourney?.id,
          filesToBeSigned: document?.filesToBeSigned ?? [],
        });
        setIsSignatureRequired(!!document?.filesToBeSigned?.length);
      } else {
        setIsSignatureRequired(false);
      }
      setAttachedFiles(document.files ?? []);
    } else {
      if (selectedJourney?.id) {
        let newBody = { ...body, journey: selectedJourney.id };
        if (!newBody.requiredUser) newBody.requiredUser = currentUser?.id ?? currentUser?._id ?? undefined;
        if (!newBody.user) newBody.user = user?.id ?? user?._id ?? undefined;
        setBody(newBody);
      }
    }

    if (documentTypes?.length) {
      setFiles([]);
      setOriginalFiles([]);
      if (document && purpose !== 'create') {
        const documentTypeObj = documentTypes.find((dt) => dt.id === document?.documentType?.id);
        setType(documentTypeObj ?? {});
        const newCategory = documentTypeObj?.category ?? defineCategoryByLimitation();
        setCategory(newCategory);
        setTypes(defineTypesByLimitation(newCategory));
        if (!documentTypeObj?.id) {
          setType(defineTypeByLimitation());
        }
      } else {
        setCategory(defineCategoryByLimitation());
        setTypes(defineTypesByLimitation(defineCategoryByLimitation()));
        setType(defineTypeByLimitation());
      }
    }
  }, [selectedJourney, documentTypes, document, limitationMode, currentUser, applicants, purpose, user]); //eslint-disable-line

  useEffect(() => {
    setUploadDisabled(
      !type?.id || (purpose !== 'create' && !files?.length && !attachedFiles?.length && (userRole === USER_ROLE.Customer || isPartner(user)))
      || (!files?.length && body?.currentStatus !== 'Pending' && !attachedFiles?.length && (userRole === USER_ROLE.Customer || isPartner(user)))
      || (!files?.length && !attachedFiles?.length && limitationMode === 'certificateOfCurrency')
      || (purpose === 'create' &&  (!category || (!selectedJourney?.id && !limitationMode) || ((userRole === USER_ROLE.Customer || isPartner(user)) && !files?.length)))
      || (!!mustBeInternal && (!category || !files?.length))
      || (isSignatureRequired && !originalFiles?.length && !body?.filesToBeSigned?.length)
    );
  }, [files, type, body, limitationMode, attachedFiles, isSignatureRequired, originalFiles, user]); //eslint-disable-line

  useEffect(() => {
    if (isDownloading) {
      window.document.body.appendChild(rootEl);
      ReactDOM.render(spinner, rootEl);
    } else if (window.document.body.contains(window.document.getElementById('spinnerWrapperAFMID'))) {
      try { window.document.body.removeChild(rootEl); } catch {}
    }
  }, [isDownloading]); //eslint-disable-line

  useEffect(() => {
    let newAvailableModes = [];
    if (
      mode === 'files'
      || purpose === 'view'
    ) newAvailableModes.push('files');
    if ((mode === 'original' || (!!isSignatureRequired)) && !['not-editable', 'loanValuationReport', 'certificateOfCurrency'].includes(limitationMode)) newAvailableModes.push('original');
    if (
      mode === 'new'
      || purpose === 'attach'
      || purpose === 'create'
      || (purpose === 'view' && ((!!userRole && userRole !== USER_ROLE.Customer && !isPartner(user)) || ['Pending', 'Uploaded'].includes(document?.currentStatus)) && !['not-editable'].includes(limitationMode))
    ) newAvailableModes.push('new');
    setAvailableModes(newAvailableModes);
  }, [purpose, userRole, document, mode, isSignatureRequired, user]); //eslint-disable-line

  useEffect(() => {
    if (!isSignatureRequired) {
      if (purpose !== 'view') setMode('new');
      else setMode('files');
    }
  }, [isSignatureRequired]); //eslint-disable-line

  const defineCategoryByLimitation = () => {
    switch (limitationMode) {
      case 'identity':
        return 'Identity';
      case 'loanConditionalApproval':
      case 'loanFormalApproval':
        return 'Loan';
      case 'exchangedContract':
        return 'Contract';
      case 'certificateOfCurrency':
        return documentTypes?.find((dt) => dt.uid === 'building_insurance')?.category ?? 'Asset';
      case 'property_contract':
        return documentTypes?.find((dt) => dt.uid === 'property_contract')?.category ?? 'Contract';
      case 'related_documents':
        return documentTypes?.find((dt) => dt.uid === 'related_documents')?.category ?? 'Contract';
      case 'reviewed_contract_of_sale':
        return documentTypes?.find((dt) => dt.uid === 'reviewed_contract')?.category ?? 'Contract';
      case 'loanValuationReport':
        return 'Loan';
      case 'settlement_finalization':
      default:
        return null;
    }
  };

  const defineTypesByLimitation = (category) => {
    const categoryTypes = category ? documentTypes.filter((dt) => dt.category === category) : [];

    switch (limitationMode) {
      case 'loanConditionalApproval':
        return categoryTypes.filter((ct) => ct.uid === 'conditional_approval');
      case 'loanFormalApproval':
        return categoryTypes.filter((ct) => ct.uid === 'formal_approval');
      case 'exchangedContract':
        return categoryTypes.filter((ct) => ct.uid === 'exchanged_contract');
      case 'certificateOfCurrency':
        return categoryTypes.find((dt) => dt.uid === 'building_insurance');
      case 'property_contract':
        return categoryTypes.filter((ct) => ct.uid === 'property_contract');
      case 'related_documents':
        return categoryTypes.filter((ct) => ct.uid === 'related_documents');
      case 'loanValuationReport':
        return categoryTypes.filter((ct) => ct.uid === 'valuation_report');
      case 'reviewed_contract_of_sale':
        return categoryTypes?.filter((ct) => ct.uid === 'reviewed_contract');
      case 'identity':
      case 'settlement_finalization':
      default:
        return categoryTypes;
    }
  };

  const defineTypeByLimitation = () => {
    switch (limitationMode) {
      case 'loanConditionalApproval':
        return documentTypes.find((dt) => dt.uid === 'conditional_approval');
      case 'loanFormalApproval':
        return documentTypes.find((dt) => dt.uid === 'formal_approval');
      case 'exchangedContract':
        return documentTypes.find((ct) => ct.uid === 'exchanged_contract');
      case 'certificateOfCurrency':
        return documentTypes.find((dt) => dt.uid === 'building_insurance');
      case 'property_contract':
        return documentTypes.find((dt) => dt.uid === 'property_contract');
      case 'related_documents':
        return documentTypes.find((dt) => dt.uid === 'related_documents');
      case 'loanValuationReport':
        return documentTypes.find((dt) => dt.uid === 'valuation_report');
      case 'reviewed_contract_of_sale':
        return documentTypes.find((dt) => dt.uid === 'reviewed_contract');
      case 'identity':
      case 'settlement_finalization':
      default:
        return {};
    }
  };

  const setInputTouched = (id) => {
    if (id > touchedInputs) setTouchedInputs(id);
  };

  const ifValidationActive = (id) => {
    return (id <= touchedInputs);
  };

  const onChangeCategory = (newValue) => {
    setCategory(newValue);
    const newTypes = documentTypes.filter((dt) => dt.category === newValue);
    setTypes(newTypes);
    setType({});
  }

  const deleteFile = (documentId, fileId, fileName) => {
    setConfirmationParameters({
      entityType: 'file',
      entityName: fileName,
      action: () => {
        dispatch(removeDocumentFile({
          documentId,
          fileId,
          onSuccess: (data) => {
            let attachedUpd = deepClone(attachedFiles);
            attachedUpd = attachedUpd.filter((af) => fileId !== af.key);
            setAttachedFiles(attachedUpd);
            updateDocumentsList(data, false);
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to remove file');
          }
        }));
      }
    });
    setConfirmationOpen(true);
  };

  const deleteOriginalFile = (documentId, fileId, fileName) => {
    setConfirmationParameters({
      entityType: 'file',
      entityName: fileName,
      action: () => {
        dispatch(removeDocumentOriginalFile({
          documentId,
          fileId,
          onSuccess: (data) => {
            let attachedUpd = deepClone(body.filesToBeSigned);
            attachedUpd = attachedUpd.filter((af) => fileId !== af.key);
            let newBody = deepClone(body);
            newBody.filesToBeSigned = attachedUpd;
            setBody(newBody);
            if (purpose === 'view') {
              setIsSignatureRequired(false);
              setMode('files');
            }
            updateDocumentsList(data, false);
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to remove file');
          }
        }));
      }
    });
    setConfirmationOpen(true);
  };

  const selectNewUser = (newUserId) => {
    if (purpose === 'creation') {
      setBody({ ...body, requiredUser: newUserId });
    } else {
      setBody({ ...body, requiredUser: newUserId });
    }
  };

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const doDownload = (file, original = false) => {
    setIsDownloading(true);
    dispatch((original ? downloadOriginalFile : downloadFile)({
      fileKey: file.fileKey,
      onError: (data) => {
        displayErrorMessage(data, 'Unable to download file');
        setIsDownloading(false);
      },
      onSuccess: (data) => {
        if (data.fileLocation) {
          axios.get(data.fileLocation, {responseType: 'arraybuffer'})
            .then((result) => {
              if (downloadUrl) window.URL.revokeObjectURL(downloadUrl);
              let generatedFile;
              try {
                generatedFile = new File([result.data], file.originalName, getFileTypeObject(file.originalName));
              } catch (e) {
                generatedFile = new Blob([result.data], getFileTypeObject(file.originalName));
              }
              let url = window.URL.createObjectURL(generatedFile);

              setDownloadUrl(url);
              let link = window.document.createElement('a');
              link.href = url;
              link.setAttribute('download', file.originalName ?? 'Document');
              //link.target = '_blank';
              window.document.body.appendChild(link);

              setTimeout(() => {
                link.click();
                setIsDownloading(false);
                try { window.document.body.removeChild(link); } catch {}
                setTimeout(() => {
                  if (downloadUrl) try { window.URL.revokeObjectURL(downloadUrl); } catch {}
                }, 100);
              }, 10);

            })
            .catch((result) => {
              displayErrorMessage(result.data, 'Unable to download file')
            });
        }
      }
    }));
  };

  const doPreview = (file, original = false) => {
    const destination = `${config.ORIGIN}/document-preview/${file.fileKey}?name=${encodeURI(file.originalName ?? 'Document')}${original ? '&original=true' : ''}`
    window.open(destination);
  };

  const filterJourneysOptions = (options) => {
    if (!journeySearchString) return options;
    else {
      const regex = new RegExp(journeySearchString, 'i');
      return options.filter((option) => !!option.name.match(regex));
    }
  };

  const getJourneySelectorError = (id) => {
    return !!selectedJourney || (!ifValidationActive(id)) ? null : 'Lender is required';
  };

  const createMarkup = (content) => {
    return {
      __html: content
    };
  };

  const renderFilesBlock = (files, original = false) => {
    return (<>
      {files.map((file, idx) => {
        return (
          <Grid
            item
            container
            xs={12}
            className={styles.fileItem}
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            key={idx}
          >
            <Grid
              item
              container
              className={styles.name}
              alignContent="center"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
            >
              <IconButton
                aria-label="download"
                onClick={() => { if (!isUploading) doDownload(file, original); }}
                className={styles.iconButton}
                disabled={isUploading}
              >
                <CloudDownloadIcon />
              </IconButton>
              {(!isMobileSafari && isFilePreviewSupported(file)) && (
                <IconButton
                  aria-label="preview"
                  onClick={() => { if (!isUploading) doPreview(file, original); }}
                  className={styles.iconButton}
                  disabled={isUploading}
                >
                  <VisibilityIcon />
                </IconButton>
              )}
              <Grid item className={`${styles.fileNameWrapper} ${(isMobileSafari || !isFilePreviewSupported(file)) ? styles.mobileSafari : ''}`}>
                <span onClick={() => { if (!isUploading) {
                  if (!isMobileSafari && isFilePreviewSupported(file)) doPreview(file, original);
                  else doDownload(file, original);
                } }}>{file.originalName}</span>
              </Grid>
              {(
                ((!!userRole && userRole !== USER_ROLE.Customer && !isPartner(user)) || (['Pending'].includes(document?.currentStatus) && mode !== 'original') || (['Uploaded'].includes(document?.currentStatus) && mode !== 'original' && limitationMode === 'certificateOfCurrency'))
                  && limitationMode !== 'not-editable' && !preventFileRemoving
              ) && (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    if (mode !== 'original') deleteFile(document?.id, file.key, file.originalName);
                    else deleteOriginalFile(document?.id, file.key, file.originalName);
                  }}
                  className={styles.iconButton}
                  disabled={isUploading}
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>);
  };

  return (
    <Dialog
      open={open}
      className={'AttachFilesDialog'}
      onClose={() => {
        if (purpose !== 'view') setMode('new');
        else setMode('files');
        setIsSignatureRequired(false);
      }}
      fullScreen={dimensions.isScreenLessMD}
    >
      <MuiDialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item className={`${styles.modalTitle} ${dimensions.isScreenLessMD ? styles.mobile : ''}`} xs={12} md={7}>
            {purpose === 'attach' ? 'Attach files' : purpose === 'create' ? 'Add new document' : 'View document'}
          </Grid>
          {(userRole !== USER_ROLE.Customer && !isPartner(user) && (!limitationMode || limitationMode === 'loanValuationReport') && !mustBeInternal) && (
            <Grid item xs={12} md={3}>
              {dimensions.isScreenLessMD && (
                <Box sx={{ mt: 4, mb: 2 }} className={styles.info}>
                  Status
                </Box>
              )}
              <FormControl variant="outlined" className={styles.selectWrapper}>
                <Select
                  value={body?.currentStatus ?? null}
                  onChange={(e) => {
                    setBody({...body, currentStatus: e.target.value});
                    setInputTouched(1);
                  }}
                  onBlur={() => setInputTouched(1)}
                  placeholder="Select status"
                  style={{ backgroundColor: "white" }}
                  disabled={isUploading || !isEditingSupported}
                  className={'CENTERED_SELECTOR'}
                >
                  {(DOCUMENT_STATUSES).map((option) => {
                    return (
                      <MenuItem value={option} key={option}>
                        <Grid container>
                          <Grid item xs={12} className={styles.optionType}>{option}</Grid>
                        </Grid>
                      </MenuItem>
                    );
                  })}
                </Select>
                <ValidationErrorLabel
                  errorMessage={ifValidationActive(1) && !body?.currentStatus ? 'Status is required' : ''}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
        <IconButton aria-label="close" className={styles.closeButton} onClick={() => setOpen(false)} disabled={isUploading}>
          <CloseRoundedIcon />
        </IconButton>
        {(!!journeys?.length && (purpose === 'create' || (purpose === 'view' && !!userRole && userRole !== USER_ROLE.Customer && !isPartner(user))) && (!hideJourneySelector)) && (<>
          <Box sx={{ mt: 4, mb: 2 }} className={styles.info}>
            Journey
          </Box>
          <Autocomplete
            options={filterJourneysOptions(journeys ?? [])}
            getOptionLabel={(option) => option?.name ?? ''}
            style={{ maxWidth: '100%', width: '100%', height: '32px', backgroundColor: "white" }}
            value={selectedJourney}
            filterOptions={(options) => options}
            disabled={purpose !== 'create' || isUploading || (mustBeInternal && definedJourney) || !isEditingSupported}
            onChange={(e, newVal) => {
              setSelectedJourney(newVal);
              setInputTouched(2);
            }}
            onBlur={() => setInputTouched(2)}
            renderInput={(params) => <TextField
              {...params}
              style={{ maxWidth: '100%', backgroundColor: "white" }}
              onChange={(event) => {
                setJourneySearchString(event.target.value);
              }}
              onFocus={(event) => {
                if (!event.target.value) setJourneySearchString(event.target.value);
              }}
              onBlur={() => setInputTouched(2)}
              placeholder="Choose a journey"
              variant="outlined"
              maxLength="200"
              error={!!getJourneySelectorError(2)}
              helperText={getJourneySelectorError(2)}
            />}
          />
        </>)}

        {(selectedJourney?.id && !!userRole && userRole !== USER_ROLE.Customer && !isPartner(user) && (!limitationMode || limitationMode === 'settlement_finalization')) && (<>
          <Box sx={{ mt: 4, mb: 2 }} className={styles.info}>
            Required user
          </Box>
          <FormControl variant="outlined" className={styles.selectWrapper}>
            <Select
              value={body?.requiredUser ?? ''}
              onChange={(e) => {
                selectNewUser(e.target.value);
                setInputTouched(3);
              }}
              onBlur={() => setInputTouched(3)}
              placeholder="Select required user"
              style={{ backgroundColor: "white" }}
              displayEmpty
              disabled={isUploading || !isEditingSupported}
            >
              <MenuItem disabled value={''} style={{ display: "none" }}>
                <div style={{ color: DISABLED_COLOR }}>Choose an applicant</div>
              </MenuItem>
              {journeyUsers.map((option) => {
                return (
                  <MenuItem value={option.id} key={option.id}>
                    <Grid container>
                      <Grid item xs={12} className={styles.optionType}>{option.name} ({option.isPrimary ? 'primary' : 'secondary'})</Grid>
                    </Grid>
                  </MenuItem>
                );
              })}
            </Select>
            <ValidationErrorLabel
              errorMessage={ifValidationActive(3) && !body?.requiredUser ? 'User is required' : ''}
            />
          </FormControl>
        </>)}

        {((!!selectedJourney?.id || purpose !== 'create' || !!limitationMode) && !isCategoryDisabled && !isTypeDisabled) && (<>
          <Box sx={{ mt: 4 }} className={styles.info}>
            <FormControl variant="outlined" className={styles.selectWrapper}>
              <Select
                value={category ?? ''}
                onChange={(e) => {
                  onChangeCategory(e.target.value);
                  setInputTouched(4);
                }}
                onBlur={() => setInputTouched(4)}
                disabled={isCategoryDisabled || isUploading || !isEditingSupported}
                placeholder="Select document category"
                style={{ backgroundColor: "white" }}
                displayEmpty
              >
                <MenuItem disabled value={''} style={{ display: "none" }}>
                  <div style={{ color: DISABLED_COLOR }} className={styles.optionType}>Choose a document category</div>
                </MenuItem>
                {possibleCategories.map((option) => {
                  return (
                    <MenuItem value={option} key={option}>
                      <Grid container>
                        <Grid item xs={12} className={styles.optionType}>{option}</Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </Select>
              <ValidationErrorLabel
                errorMessage={ifValidationActive(4) && !category ? 'Category is required' : ''}
              />
            </FormControl>
          </Box>
        </>)}

        {isCategoryDisabled && !isTypeDisabled && (
          <Box sx={{ mt: 2}} className={styles.docTypeLabel}>
            {category ?? ''}
          </Box>
        )}

        {category && !isTypeDisabled && (<>
          <Box sx={{ mt: 2}}>
            <FormControl variant="outlined" className={styles.selectWrapper}>
              <Select
                value={type?.id ? type : ''}
                onChange={(e) => {
                  setType(e.target.value);
                  setInputTouched(5);
                }}
                onBlur={() => setInputTouched(5)}
                style={{ backgroundColor: "white" }}
                disabled={isTypeDisabled || isUploading || !isEditingSupported}
                displayEmpty
              >
                <MenuItem disabled value={''} style={{ display: "none" }}>
                  <div style={{ color: DISABLED_COLOR }} className={styles.optionType}>Choose a document type</div>
                </MenuItem>
                {types.map((option) => {
                  return (
                    <MenuItem value={option} key={option.id}>
                      <Grid container>
                        <Grid item xs={12} className={styles.optionType}>{option.type}</Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </Select>
              <ValidationErrorLabel
                errorMessage={ifValidationActive(5) && !type?.id ? 'Type is required' : ''}
              />
            </FormControl>
          </Box>
        </>)}

        {(isCategoryDisabled && isTypeDisabled) && (
          <Box sx={{ mt: 2 }} className={styles.docTypeLabel}>
            {category ?? '???'} / {type?.id ? type?.type : ''}
          </Box>
        )}

        <Box
          className={styles.captionBlock}
          sx={{ mt: 1 }}
          dangerouslySetInnerHTML={createMarkup(document?.customDescription ?? document?.documentType?.longDescription ?? '')}
        />

        {(selectedJourney?.id && !!userRole && userRole !== USER_ROLE.Customer && !isPartner(user) && (!limitationMode || limitationMode === 'settlement_finalization') && !mustBeInternal && !hideCheckBoxes) && (
          <Box sx={{ mt: 1, mb: 2, position: "relative", left: "0" }}>
            <Grid container spacing={0}>
              <Grid container item spacing={2} xs={12} sm={6} alignItems="center">
                <Grid item>
                  <Checkbox
                    checked={isSignatureRequired}
                    onChange={() => {
                      setIsSignatureRequired(!isSignatureRequired);
                      setInputTouched(6);
                    }}
                    onBlur={() => setInputTouched(6)}
                    size={"small"}
                    disabled={isUploading || !isEditingSupported || body.filesToBeSigned?.length}
                  />
                </Grid>
                <Grid item className={styles.checkboxLabel}>Signature required</Grid>
              </Grid>
              <Grid container item spacing={2} xs={12} sm={6} alignItems="center">
                <Grid item>
                  <Checkbox
                    checked={!!body?.includeSubTypes}
                    onChange={() => {
                      setBody({...body, includeSubTypes: !body?.includeSubTypes});
                      setInputTouched(6.5);
                    }}
                    onBlur={() => setInputTouched(6.5)}
                    size={"small"}
                    disabled={isUploading || !isEditingSupported}
                  />
                </Grid>
                <Grid item className={styles.checkboxLabel}>Allow to change type</Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        {(selectedJourney?.id && !!userRole && userRole !== USER_ROLE.Customer && !isPartner(user) && (!limitationMode || ['settlement_finalization', 'loanValuationReport'].includes(limitationMode))) && (
          <Box sx={{ mt: 1, mb: 1 }}>
            <Grid container>
              <TextareaAutosize
                placeholder="Add custom message here..."
                multiline={'true'}
                minRows={3}
                maxRows={3}
                className={styles.textArea}
                value={body.customDescription}
                onChange={(e) => {
                  setBody({...body, customDescription: e.target.value});
                  setInputTouched(7);
                }}
                onBlur={() => setInputTouched(7)}
                maxLength={256}
                disabled={isUploading || !isEditingSupported}
              />
            </Grid>
          </Box>
        )}

      </MuiDialogTitle>
      <MuiDialogContent>
        {availableModes.length > 1 && (
          <Grid container>
            {availableModes.map((am) => (
              <Grid
                item
                xs={availableModes.length === 3 ? 4 : 6}
                className={`${styles.modeOption} ${mode === am ? styles.active : ''}`}
                onClick={() => setMode(am)}
                key={am}
              >
                {am === 'files' ? 'Uploaded files' : am === 'original' ? 'Original file to sign' : 'Upload new'}
              </Grid>
            ))}
          </Grid>
        )}
        <Box sx={{ mt: 2 }}>
          {mode === 'files' ? (<>
            {attachedFiles?.length ? (<>
              <Box className={styles.filesContainer}>
                {renderFilesBlock(attachedFiles)}
              </Box>
            </>) : (<Grid item xs={12} className={styles.emptyBlock} key={-1}>
              <p>No files found for the given document, please upload files</p>
            </Grid>) }
          </>) : mode === 'original' ? (<>
            {!!body.filesToBeSigned?.length ? (<>
              <Box className={styles.filesContainer}>
                {renderFilesBlock(body.filesToBeSigned, true)}
              </Box>
            </>) : (
              <DocumentDropzone
                files={originalFiles}
                setFiles={setOriginalFiles}
                disabled={!type?.id || isUploading || !isEditingSupported}
                userRole={userRole}
                singleFile
              />
            )}
          </>) : (
            <DocumentDropzone
              files={files}
              setFiles={setFiles}
              disabled={!type?.id || isUploading || !isEditingSupported}
              userRole={userRole}
            />
          )}
        </Box>
      </MuiDialogContent>
      <MuiDialogActions>
        <Grid container justifyContent="space-between">
          <Button
            className={`${styles.actionButton} ${styles.secondary}`}
            onClick={() => setOpen(false)}
            color="secondary"
            variant="outlined"
            type="button"
            disableElevation
            disabled={isUploading}
          >
            CANCEL
          </Button>
          {(!(purpose === 'view' && (userRole === USER_ROLE.Customer || isPartner(user)) && !files?.length) && isEditingSupported) && (
            <Button
              className={`${styles.actionButton}`}
              type="button"
              variant="contained"
              color="primary"
              disabled={isUploading || uploadDisabled || (limitationMode === 'reviewed_contract_of_sale' && !files?.length)}
              onClick={() => {
                if (isEditingSupported) onUpload(document, files, isSignatureRequired ? originalFiles : [], type, purpose === 'create', body, mustBeInternal);
                else setOpen(false);
              }}
            >
              {isUploading && <CircularProgress size={14} className={styles.buttonProgress} />}{'SUBMIT'}
            </Button>
          )}
        </Grid>
      </MuiDialogActions>
      <ConfirmDeletionModal open={confirmationOpen} setOpen={setConfirmationOpen} parameters={confirmationParameters}/>
      <ProgressModal
        open={openProgressModal}
        setOpen={setOpenProgressModal}
        filesNumber={files?.length ?? 0}
        progress={currentProgress ?? 0}
      />
    </Dialog>
  );
};

AttachFilesModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  document: PropTypes.object,
  documents: PropTypes.arrayOf(PropTypes.object),
  onUpload: PropTypes.func,
  isUploading: PropTypes.bool,
  currentProgress: PropTypes.number,
  updateDocumentsList: PropTypes.func,
  purpose: PropTypes.string,
  journeys: PropTypes.arrayOf(PropTypes.object),
  definedJourney: PropTypes.object,
  documentTypes: PropTypes.arrayOf(PropTypes.object),
  userRole: PropTypes.string,
  limitationMode: PropTypes.string,
  applicants: PropTypes.arrayOf(PropTypes.object),
  mustBeInternal: PropTypes.bool,
  hideJourneySelector: PropTypes.bool,
  hideCheckBoxes: PropTypes.bool,
  preventFileRemoving: PropTypes.bool
};

export default withDimensions(AttachFilesModal);


import {call, takeLatest, takeEvery, debounce} from 'redux-saga/effects';
import axios from 'axios';
import queryString from 'query-string';

import { isFunc } from '@utils/functions';

const Api = {
  getCases: ({
               limit,
               page,
               search,
               sort,
               currentStepUid,
               specialist,
               journeyOwner,
               assignee,
               caseStatus,
               type,
               creator,
               createdLastDays,
               updatedLastDays,
               journeyId,
               onlyIfUpdatedSince,
               urgent
  }) => {
    let params = {
      limit: limit ?? 10
    };
    if (journeyId) params.journeyId = journeyId;
    if (type) params.type = type;
    if (journeyOwner) params.journeyOwner = journeyOwner;
    if (assignee) params.assignee = assignee;
    if (creator) params.creator = creator;
    if (search) params.search = search;
    if (sort) params.sort = sort;
    if (currentStepUid) params.currentStepUid = currentStepUid;
    if (specialist) params.specialist = specialist;
    if (page || page === 0) params.page = page;
    if (createdLastDays) params.createdLastDays = createdLastDays;
    if (updatedLastDays) params.updatedLastDays = updatedLastDays;
    if (!Array.isArray(caseStatus) ? !!caseStatus : !!caseStatus.length) params.caseStatus = caseStatus;
    if (onlyIfUpdatedSince) params.onlyIfUpdatedSince = onlyIfUpdatedSince;
    if (typeof urgent === 'boolean') params.urgent = urgent;

    return axios.request({
      url: '/allowedCases',
      method: 'get',
      params,
      paramsSerializer: params => {
        return queryString.stringify(params)
      }
    });
  },
  getCaseTypes: () => axios.get(`/dictionary/caseTypes`),
  createCase: (body) => axios.post(`/allowedCases`, body),
  getCase: ({ id, notExtended }) => axios.get(`/allowedCases/${id}${!!notExtended ? '?notExtended=true' : ''}`),
  updateCase: ({id, body}) => axios.put(`/allowedCases/${id}`, body),
  deleteCase: (id) => axios.delete(`/allowedCases/${id}`),
  resumeCases: () => axios.get(`/allowedCases/resume`),
  getAndUpdateEditors: (id) => axios.get(`/allowedCases/${id}/editorsUpdate`),
  createCaseTimeLog: (body) => axios.post(`/allowedCases/timeLog`, body),
  updateCaseTimeLog: ({ logId, minutes }) => axios.put(`/allowedCases/timeLog/${logId}`, { minutes }),
  getCaseTimeLogs: (caseId) => axios.get(`/allowedCases/${caseId}/timeLog`),
  listTimeLogs: ({
                  limit,
                  page,
                  sort,
                  specialist,
                  journeyOwner,
                  journeyId,
                  type,
                  entryLogger,
                  fromDate,
                  toDate
                }) => {
    let params = {
      limit: limit ?? 10
    };
    if (type) params.type = type;
    if (journeyOwner) params.customer = journeyOwner;
    if (journeyId) params.journey = journeyId;
    if (entryLogger) params.user = entryLogger;
    if (sort) params.sort = sort;
    if (specialist) params.specialist = specialist;
    if (page || page === 0) params.page = page;
    if (fromDate) params.fromDate = fromDate;
    if (toDate) params.toDate = toDate;

    return axios.request({
      url: '/listTimeLogs',
      method: 'get',
      params,
      paramsSerializer: params => {
        return queryString.stringify(params)
      }
    });
  },
  listTimeLogsCSV: ({
                   sort,
                   specialist,
                   journeyOwner,
                   journeyId,
                   type,
                   entryLogger,
                   fromDate,
                   toDate
                 }) => {
    let params = {};
    if (type) params.type = type;
    if (journeyOwner) params.customer = journeyOwner;
    if (journeyId) params.journey = journeyId;
    if (entryLogger) params.user = entryLogger;
    if (sort) params.sort = sort;
    if (specialist) params.specialist = specialist;
    if (fromDate) params.fromDate = fromDate;
    if (toDate) params.toDate = toDate;

    return axios.request({
      url: '/listTimeLogs/csv',
      method: 'get',
      params,
      paramsSerializer: params => {
        return queryString.stringify(params)
      }
    });
  },
  getCaseTimeLog: (logId) => axios.get(`/allowedCases/timeLog/${logId}`),
  deleteCaseTimeLog: (logId) => axios.delete(`/allowedCases/timeLog/${logId}`)
};

export const actionTypes = {
  GET_CASES: 'GET_CASES',
  GET_CASES_WITH_DEBOUNCE: 'GET_CASES_WITH_DEBOUNCE',
  GET_CASE_TYPES: 'GET_CASE_TYPES',
  GET_CASE: 'GET_CASE',
  CREATE_CASE: 'CREATE_CASE',
  UPDATE_CASE: 'UPDATE_CASE',
  DELETE_CASE: 'DELETE_CASE',
  RESUME_CASES: 'RESUME_CASES',
  GET_AND_UPDATE_EDITORS: 'GET_AND_UPDATE_EDITORS',
  CREATE_CASE_TIME_LOG: 'CREATE_CASE_TIME_LOG',
  UPDATE_CASE_TIME_LOG: 'UPDATE_CASE_TIME_LOG',
  GET_CASE_TIME_LOGS: 'GET_CASE_TIME_LOGS',
  GET_CASE_TIME_LOG: 'GET_CASE_TIME_LOG',
  DELETE_CASE_TIME_LOG: 'DELETE_CASE_TIME_LOG',
  LIST_TIME_LOGS: 'LIST_TIME_LOGS',
  LIST_TIME_LOGS_CSV: 'LIST_TIME_LOGS_CSV'
};

function* sagaGetCases({ payload: {
  limit,
  page,
  search,
  sort,
  specialist,
  journeyOwner,
  assignee,
  caseStatus,
  type,
  creator,
  createdLastDays,
  updatedLastDays,
  journeyId,
  onlyIfUpdatedSince,
  urgent,
  onSuccess,
  onError
} }) {
  try {
    const { data } = yield call(Api.getCases, {
      limit,
      page,
      search,
      sort,
      specialist,
      journeyOwner,
      assignee,
      caseStatus,
      type,
      creator,
      createdLastDays,
      updatedLastDays,
      journeyId,
      onlyIfUpdatedSince,
      urgent
    });
    //yield put({ type: journeysActionType.JOURNEYS, payload: data });
    if (data.elements || data.notModified) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCaseTypes({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCaseTypes);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCase({ payload: { id, notExtended, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCase, {id, notExtended});
    if (result.status === 204 && !!notExtended) {
      if (isFunc(onSuccess)) onSuccess();
    } else if (result.data?.id) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateCase({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateCase, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateCase({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createCase, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteCase({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteCase, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaResumeCases({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.resumeCases);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAndUpdateEditors({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAndUpdateEditors, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateCaseTimeLog({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createCaseTimeLog, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateCaseTimeLog({ payload: { logId, minutes, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateCaseTimeLog, { logId, minutes });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCaseTimeLogs({ payload: { caseId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCaseTimeLogs, caseId);

    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCaseTimeLog({ payload: { logId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCaseTimeLog, logId);

    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteCaseTimeLog({ payload: { logId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteCaseTimeLog, logId);

    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaListTimeLogs({ payload: { limit, page, sort, specialist, journeyOwner, journeyId, type, entryLogger, fromDate, toDate, onSuccess, onError } }) {
  try {
    const result = yield call(Api.listTimeLogs, { limit, page, sort, specialist, journeyOwner, journeyId, type, entryLogger, fromDate, toDate });

    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaListTimeLogsCSV({ payload: { sort, specialist, journeyOwner, journeyId, type, entryLogger, fromDate, toDate, onSuccess, onError } }) {
  try {
    const result = yield call(Api.listTimeLogsCSV, { sort, specialist, journeyOwner, journeyId, type, entryLogger, fromDate, toDate });

    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getCases = payload => ({ type: actionTypes.GET_CASES, payload });
export const getCasesWithDebounce = payload => ({ type: actionTypes.GET_CASES_WITH_DEBOUNCE, payload });
export const getCaseTypes = payload => ({ type: actionTypes.GET_CASE_TYPES, payload });
export const getCase = payload => ({ type: actionTypes.GET_CASE, payload });
export const createCase = payload => ({ type: actionTypes.CREATE_CASE, payload });
export const updateCase = payload => ({ type: actionTypes.UPDATE_CASE, payload });
export const deleteCase = payload => ({ type: actionTypes.DELETE_CASE, payload });
export const resumeCases = payload => ({ type: actionTypes.RESUME_CASES, payload });
export const getAndUpdateEditors = payload => ({ type: actionTypes.GET_AND_UPDATE_EDITORS, payload });
export const createCaseTimeLog = payload => ({ type: actionTypes.CREATE_CASE_TIME_LOG, payload });
export const updateCaseTimeLog = payload => ({ type: actionTypes.UPDATE_CASE_TIME_LOG, payload });
export const getCaseTimeLogs = payload => ({ type: actionTypes.GET_CASE_TIME_LOGS, payload });
export const getCaseTimeLog = payload => ({ type: actionTypes.GET_CASE_TIME_LOG, payload });
export const deleteCaseTimeLog = payload => ({ type: actionTypes.DELETE_CASE_TIME_LOG, payload });
export const listTimeLogs = payload => ({ type: actionTypes.LIST_TIME_LOGS, payload });
export const listTimeLogsCSV = payload => ({ type: actionTypes.LIST_TIME_LOGS_CSV, payload });

const casesSagas = [
  takeLatest(actionTypes.GET_CASES, sagaGetCases),
  debounce(900, actionTypes.GET_CASES_WITH_DEBOUNCE, sagaGetCases),
  takeLatest(actionTypes.GET_CASE_TYPES, sagaGetCaseTypes),
  takeEvery(actionTypes.GET_CASE, sagaGetCase),
  takeEvery(actionTypes.CREATE_CASE, sagaCreateCase),
  takeEvery(actionTypes.UPDATE_CASE, sagaUpdateCase),
  takeEvery(actionTypes.DELETE_CASE, sagaDeleteCase),
  takeLatest(actionTypes.RESUME_CASES, sagaResumeCases),
  takeLatest(actionTypes.GET_AND_UPDATE_EDITORS, sagaGetAndUpdateEditors),
  takeLatest(actionTypes.GET_CASE_TIME_LOG, sagaGetCaseTimeLog),
  takeLatest(actionTypes.GET_CASE_TIME_LOGS, sagaGetCaseTimeLogs),
  takeLatest(actionTypes.CREATE_CASE_TIME_LOG, sagaCreateCaseTimeLog),
  takeLatest(actionTypes.UPDATE_CASE_TIME_LOG, sagaUpdateCaseTimeLog),
  takeLatest(actionTypes.DELETE_CASE_TIME_LOG, sagaDeleteCaseTimeLog),
  debounce(400, actionTypes.LIST_TIME_LOGS, sagaListTimeLogs),
  takeEvery(actionTypes.LIST_TIME_LOGS_CSV, sagaListTimeLogsCSV),
];

export default casesSagas;
